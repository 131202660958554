import {
  ExpandOutlined,
  InsertRowBelowOutlined,
  PieChartOutlined,
  ReconciliationOutlined,
  RocketFilled,
  SettingFilled
} from "@ant-design/icons";

import {
  CARRIER, CATALOG, CATALOG_COLOR, CATALOG_CONTENT, CATALOG_EDIT, CATALOG_LIST, COMPILE_KEYWORDS, CUSTOM_LABELS,
  DESIGN_LIST,
  DESIGN_TEMPLATES,
  GROUP_COMPILE_KEYWORDS, KEYWORDS, LABELING, META_TITLE, PAGES_TEMPLATES, PREDICT_CATEGORIES,
  PRICING_TABLE, REPLACE_CHARACTERS, REPORT, REPORT_REQUEST_CATALOG, REPORT_REQUEST_CATALOG_DESIGN, REPORT_SIMILAR_CATALOG, REQUEST_HISTORY, SITE_MAPPING, STORE, STORE_CONFIG, STORE_LIST, VENDOR_TITLE
} from "../../constant/path";
import { MenuItemInterface } from "./interface";

export const dataMenu: MenuItemInterface[] = [
  {
    parentId: null,
    id: "1a",
    icon: <InsertRowBelowOutlined />,
    title: "Store",
    activePath: [STORE_LIST, STORE_CONFIG, PREDICT_CATEGORIES, CUSTOM_LABELS, REPLACE_CHARACTERS],
    children: [
      {
        parentId: "1a",
        id: "1ab",
        icon: "",
        title: "Modules",
        activePath: [STORE_LIST, STORE_CONFIG, PREDICT_CATEGORIES, CUSTOM_LABELS, REPLACE_CHARACTERS],
        children: [
          {
            parentId: "1ab",
            id: "1abc",
            icon: "",
            title: "Sites",
            href: STORE_LIST,
            activePath: [STORE_LIST, STORE_CONFIG],
            children: [],
          },
          {
            parentId: "labeling2a",
            id: "labeling3a",
            icon: "",
            title: "Categories",
            href: PREDICT_CATEGORIES,
            activePath: [LABELING, PREDICT_CATEGORIES],
            children: [],
          },
          {
            parentId: "labeling2a",
            id: "labeling4a",
            icon: "",
            title: "Custom Labels",
            href: CUSTOM_LABELS,
            activePath: [LABELING, CUSTOM_LABELS],
            children: [],
          },
          {
            parentId: "labeling2a",
            id: "labeling5a",
            icon: "",
            title: "Replace Characters",
            href: REPLACE_CHARACTERS,
            activePath: [LABELING, REPLACE_CHARACTERS],
            children: [],
          },
        ],
      },
    ],
  },
  {
    parentId: null,
    id: "2a",
    icon: <RocketFilled />,
    title: "Catalog",
    activePath: [CATALOG_LIST, PRICING_TABLE, CATALOG_CONTENT, META_TITLE, REQUEST_HISTORY,
      VENDOR_TITLE],
    children: [
      {
        parentId: "2a",
        id: "2ab",
        icon: "",
        title: "Deployment",
        activePath: [CATALOG_LIST, PRICING_TABLE, CATALOG_CONTENT, META_TITLE,
          VENDOR_TITLE, CATALOG_EDIT, DESIGN_LIST],
        children: [
          {
            parentId: "2ab",
            id: "2abc",
            icon: "",
            title: "Catalog",
            href: CATALOG_LIST,
            children: [],
            activePath: [CATALOG_LIST, CATALOG_EDIT],
          },
          {
            parentId: "2ab",
            id: "2abc-designs",
            icon: "",
            title: "Catalog Designs",
            href: DESIGN_LIST,
            children: [],
            activePath: [DESIGN_LIST],
          },
          {
            parentId: "2ab",
            id: "3abc",
            icon: "",
            title: "Pricing table",
            href: PRICING_TABLE,
            children: [],
            activePath: [PRICING_TABLE],
          },
          // {
          //   parentId: "2ab",
          //   id: "4abc",
          //   icon: "",
          //   title: "Catalog Site Mapping",
          //   href: SITE_MAPPING,
          //   children: [],
          //   activePath:[SITE_MAPPING],
          // },
          {
            parentId: "2ab",
            id: "5abc",
            icon: "",
            title: "Content",
            href: CATALOG_CONTENT,
            children: [],
            activePath: [CATALOG_CONTENT],
          },
          {
            parentId: "2ab",
            id: "6abc",
            icon: "",
            title: "Meta Title",
            href: META_TITLE,
            children: [],
            activePath: [META_TITLE],
          },
          {
            parentId: "2ab",
            id: "7abc",
            icon: "",
            title: "Vendor Title",
            href: VENDOR_TITLE,
            children: [],
            activePath: [VENDOR_TITLE],
          },
        ],
      },
      // {
      //   parentId: "2a",
      //   id: "3ab",
      //   icon: "",
      //   title: "Requirement",
      //   activePath: [CATALOG_LIST, REQUEST_HISTORY],
      //   children: [
      //     {
      //       parentId: "3ab",
      //       id: "9abc",
      //       icon: "",
      //       title: "Request History",
      //       href: REQUEST_HISTORY,
      //       children: [],
      //       activePath: [REQUEST_HISTORY],
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   parentId: null,
  //   id: "carrier",
  //   icon: <UserOutlined />,
  //   title: "Carrier",
  //   children: [
  //     {
  //       parentId: "carrier",
  //       id: "user",
  //       icon: "",
  //       title: "Users",
  //       children: [
  //         {
  //           parentId: "user",
  //           id: "user",
  //           icon: "",
  //           title: "Manager User",
  //           href: CARRIER,
  //           children: [],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    parentId: null,
    id: "labelinga",
    icon: <SettingFilled />,
    title: "Labeling",
    activePath: [LABELING, PAGES_TEMPLATES,
      GROUP_COMPILE_KEYWORDS,
      COMPILE_KEYWORDS,
      CATALOG_COLOR],
    children: [
      {
        parentId: "labelinga",
        id: "labeling2a",
        icon: "",
        title: "Configuration",
        activePath: [LABELING, PAGES_TEMPLATES,
          GROUP_COMPILE_KEYWORDS,
          COMPILE_KEYWORDS,
          CATALOG_COLOR, KEYWORDS],
        children: [

          {
            parentId: "labeling2a",
            id: "labeling6a",
            icon: "",
            title: "Pages Templates",
            href: PAGES_TEMPLATES,
            activePath: [LABELING, PAGES_TEMPLATES],
            children: [],
          },
          {
            parentId: "labeling2a",
            id: "design_templates",
            icon: "",
            title: "Design Mockup Templates",
            href: DESIGN_TEMPLATES,
            activePath: [DESIGN_TEMPLATES],
            children: [],
          },
          {
            parentId: "labeling2a",
            id: "labeling7a",
            icon: "",
            title: "Group Compile Keywords",
            href: GROUP_COMPILE_KEYWORDS,
            activePath: [LABELING, GROUP_COMPILE_KEYWORDS],
            children: [],
          },
          {
            parentId: "labeling2a",
            id: "labeling8a",
            icon: "",
            title: "Compile Keywords",
            href: COMPILE_KEYWORDS,
            activePath: [LABELING, COMPILE_KEYWORDS],
            children: [],
          },
          {
            parentId: "labeling2a",
            id: "labeling2a-keyword",
            icon: "",
            title: "Keywords",
            href: KEYWORDS,
            activePath: [KEYWORDS],
            children: [],
          },
          {
            parentId: "labeling2a",
            id: "labeling9a",
            icon: "",
            title: "Colors",
            href: CATALOG_COLOR,
            activePath: [LABELING, CATALOG_COLOR],
            children: [],
          },
        ]
      }
    ]
  },
  {
    parentId: null,
    id: "reporta",
    icon: <PieChartOutlined />,
    title: "Report",
    activePath: [REPORT_REQUEST_CATALOG],
    children: [
      {
        parentId: "reporta",
        id: "report2a",
        icon: "",
        title: "Report",
        activePath: [REPORT_REQUEST_CATALOG],
        children: [
          // {
          //   parentId: "report2a",
          //   id: "report3a",
          //   icon: "",
          //   title: "Report product labeling",
          //   href: REPORT,
          //   children: [],
          // },
          {
            parentId: "report2a",
            id: "report4a",
            icon: "",
            title: "Catalog Deploy",
            href: REPORT_REQUEST_CATALOG,
            activePath: [REPORT_REQUEST_CATALOG],
            children: [],
          },
          // {
          //   parentId: "report2a",
          //   id: "report5a",
          //   icon: "",
          //   title: "Report similar catalog",
          //   href: REPORT_SIMILAR_CATALOG,
          //   children: [],
          // },
          {
            parentId: "report2a",
            id: "report6a",
            icon: "",
            title: "Catalog Design Deploy",
            href: REPORT_REQUEST_CATALOG_DESIGN,
            activePath: [REPORT_REQUEST_CATALOG_DESIGN],
            children: [],
          },
        ]
      }
    ]
  },
  // {
  //   parentId: null,
  //   id: "carrier",
  //   icon: <UserOutlined />,
  //   title: "Carrier",
  //   children: [
  //     {
  //       parentId: "carrier",
  //       id: "user",
  //       icon: "",
  //       title: "Users",
  //       children: [
  //         {
  //           parentId: "user",
  //           id: "user",
  //           icon: "",
  //           title: "Manager User",
  //           href: CARRIER,
  //           children: [],
  //         },
  //       ],
  //     },
  //   ],
  // },

];