import { log } from 'console';

import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link, Outlet, useLocation, useMatch, useMatches, useNavigate, useNavigation, useResolvedPath } from 'react-router-dom';
import { Button, Drawer, DrawerProps, Layout, Menu, MenuProps } from 'antd';
import type { RadioChangeEvent } from 'antd/es/radio';
import { Avatar } from 'antd/lib';
import logout from 'apis/auth/logout';
import { KeyFilter } from 'constant/enum';
import { STORE_CONFIG, STORE_CONFIG_GENERAL } from 'constant/path';
import useGetUser from 'data/hooks/use-get-user';
import { removeAuth } from 'helpers/auth';
import { useAuth } from 'modules/auth/contexts/auth-context';
import { UserOutlined } from '@ant-design/icons';
import {
    faDownload,
    faEnvelopeCircleCheck,
    faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadingPage from '../../components/common/loading-pages';
import { DefaultTitle } from './page-title/DefaultTitle';
import { dataMenu } from './data-menu';
import { MenuItemInterface } from './interface';

import style from './style.module.scss';

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type
    } as MenuItem;
}

const Layouts: React.FC = () => {

    const navigate = useNavigate();



    const dataLoop = (data: any) => {
        return data?.map((item: any) => {
            if (item?.children?.length === 0) {
                return getItem(<Link to={!!item?.href ? item?.href : '#'}>{item?.title}</Link>, item?.id, item?.icon);
            } else {
                if (item.parentId === null) {
                    return getItem(<b key={item.id}>{item.title}</b>, item.id, item.icon, dataLoop(item.children));
                } else {
                    return getItem(
                        <p style={{ fontWeight: '500', color: '#fff' }} key={item.id}>
                            <Link to={!!item.href ? item.href : '#'}>{item.title}</Link>
                        </p>,
                        item.id,
                        item.icon,
                        dataLoop(item.children),
                        'group'
                    );
                }
            }
        });
    };

    const [collapsed, setCollapsed] = useState(true);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState<DrawerProps['placement']>('right');
    const logoutMutation = useMutation(() => logout());
    const { logout: logoutContext } = useAuth();

    const showDrawer = () => {
        setOpen(true);
    };

    const onChange = (e: RadioChangeEvent) => {
        setPlacement(e.target.value);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            removeAuth();
            navigate('/login');
            logoutContext();
            localStorage.removeItem('user');
            // }
        } catch (error) { }
        // localStorage.clear();
        // setTimeout(() => {
        //   setLoading(false);
        //   navigate("/redirect");
        // }, 3000);
    };
    let userId: any = null;
    try {
        userId = JSON.parse(localStorage.getItem('user') || '');
    } catch (error) { }
    const [id, setId] = useState<any>();
    const { data: dataUser } = useGetUser(id);

    useEffect(() => {
        if (!userId?.user_id) return;
        setId(userId.user_id);
    }, [userId?.user_id]);

    const openKeys: Array<string> = useMemo(() => {
        const result: Array<string> = [];

        const getKeyByPath = (data: MenuItemInterface[]): Array<string> => {

            data.forEach((item) => {


                const isOpen = item.activePath.includes(location.pathname);
                if (isOpen) {
                    if (item.children.length > 0) {
                        result.push(item.id);
                        const data = getKeyByPath(item.children);
                        result.concat(data);
                    } else {
                        result.push(item.id);
                    }
                }
            });

            return result;
        };

        return getKeyByPath(dataMenu);
    }, [location.pathname]);

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>
                {logoutMutation.isLoading && <LoadingPage />}
                <Sider
                    width={300}
                    collapsible
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                    style={{ backgroundImage: 'url("/media/bg-login.jpg")' }}
                >
                    <Link to="/catalog/catalog-list">
                        <div className={collapsed ? style.logo : style.logoCollapse}>
                            <img src="/logo2000x2000.png" alt="Logo" />
                        </div>
                    </Link>
                    <Menu
                        theme="dark"
                        defaultSelectedKeys={openKeys}
                        mode="inline"
                        items={dataLoop(dataMenu)}
                        style={{ marginTop: '10px' }}
                    // defaultOpenKeys={openKeys}
                    />
                </Sider>
                <Layout className="site-layout">
                    <Header
                        className="site-layout-background"
                        style={{
                            backgroundColor: '#fff',
                            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                            padding: 0
                        }}
                    >
                        <div className={style.header}>
                            <div className={style.pageTitle}>
                                <DefaultTitle />
                            </div>
                            <div className={style.group}>
                                <div className={style.download}>
                                    <Link to={'/download-center/download?' + KeyFilter.SessionUser + "=" + dataUser?.data?.id}>
                                        <FontAwesomeIcon icon={faDownload} fontSize="18px" cursor="pointer" className={style.iconDownload} />
                                    </Link>
                                </div>
                                <div className={style.infor} onClick={showDrawer}>
                                    <span>{dataUser?.data?.full_name}</span>
                                    <Avatar size="large" icon={<UserOutlined />} src={dataUser?.data?.avatar} />

                                </div>
                            </div>
                        </div>
                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                        <div className="site-layout-background" style={{ minHeight: 360 }}>
                            <Outlet />
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>
                        {<img src={process.env.REACT_APP_DEPLOY_STATUS} alt="" />}
                    </Footer>
                </Layout>
            </Layout>
            <div>
                <Drawer
                    title={
                        <div className={style.drawer}>
                            <div className={style.title}>
                                <span>Profile</span>
                                <span>Create 07 Nov 2022</span>
                            </div>
                            <FontAwesomeIcon icon={faXmark} fontSize="20px" cursor="pointer" onClick={onClose} />
                        </div>
                    }
                    closable={false}
                    placement={placement}
                    width={400}
                    onClose={onClose}
                    open={open}
                >
                    <div className={style.quick}>
                        {/* <div className={style.quickProfile}>
                            <div className={style.quickProfileTitle}>
                                <h4>Profile</h4>
                                <span>Create 07 Nov 2022</span>
                            </div>
                            <div className={style.close} onClick={handleHidden}>
                                <IoMdClose />
                            </div>
                        </div> */}
                        <div className={style.quickLogin}>
                            <Avatar shape="square" size={100} icon={<UserOutlined />} src={dataUser?.data?.avatar} />
                            <div>
                                <h6>{dataUser?.data?.full_name}</h6>
                                <p>Marketing</p>
                                <div className={style.inforEmail}>
                                    <div className={style.icon}>
                                        <FontAwesomeIcon icon={faEnvelopeCircleCheck} />
                                    </div>
                                    <p>{dataUser?.data?.email}</p>
                                </div>
                                <div className={style.button}>
                                    <Button size={'large'} loading={logoutMutation.isLoading} onClick={handleLogout}>
                                        Sign out
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* <div className={style.quickMore}>
              <div className={style.quickMoreItem}>
                <div className={style.moreIcon}>
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <div>
                  <Link to="/profile" onClick={() => setOpen(false)}>
                    <h6>My Profile</h6>
                    <p>Account settings</p>
                  </Link>
                </div>
              </div>
              <div className={style.quickMoreItem}>
                <div className={style.moreIcon}>
                  <FontAwesomeIcon icon={faBarsProgress} />
                </div>
                <div>
                  <h6>My Task</h6>
                  <p>Lates task and project</p>
                </div>
              </div>
              <div className={style.quickMoreItem}>
                <div className={style.moreIcon}>
                  <FontAwesomeIcon icon={faBusinessTime} />
                </div>
                <div>
                  <h6>My Profile</h6>
                </div>
              </div>
            </div> */}
                    </div>
                </Drawer>
            </div>
        </>
    );
};

export default Layouts;
